import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// Packages
import { Button, InspiColors, Text } from "@inspigoid/inspids-react";

import {
  SXBackButton,
  SXHeader,
  SXInnerContainer,
  SXLogo,
  SXLogoImage,
  SXUser,
  SXUserName,
  SXAvatar,
} from "./Header.styled";

// Components
import Section from "../Section/Section";

// utils
import { getFirstName } from "../../utils/helpers";

const Header = ({ withBackButton = false }) => {
  const { user } = useSelector(({ rootReducer }) => rootReducer);
  const location = useLocation();
  const history = useHistory();

  const userStatus = user?.status;
  const userData = user?.data;

  const authenticated = Object.keys(userData).length !== 0;

  const handleClick = () => {
    const pathnameArray = location.pathname.split("/");
    const windowPathNameArray = window.location.pathname.split("/");

    if (windowPathNameArray.length === 3) {
      return window.location.replace(process.env.REACT_APP_DOMAIN_IFB);
    }

    if (windowPathNameArray[5] == "post") {
      const forumPath = windowPathNameArray[2];
      const tagId = windowPathNameArray[4];
      return history.replace(`/forum/${forumPath}/detail/${tagId}`);
    }

    if (windowPathNameArray[3] == "detail") {
      const forumPath = windowPathNameArray[2];
      return history.replace(`/forum/${forumPath}`);
    }

    history.goBack();
  };

  return (
    <SXHeader>
      <Section>
        <SXInnerContainer>
          <SXLogo>
            {withBackButton && (
              <SXBackButton onClick={handleClick}>
                <img
                  src="/assets/icons/chevron-left.svg"
                  alt=""
                  width={40}
                  height={40}
                />
              </SXBackButton>
            )}
            <SXLogoImage>
              <img src="/assets/logo/ifb.svg" alt="" width={275} height={24} />
            </SXLogoImage>
          </SXLogo>

          {userStatus && (
            <React.Fragment>
              {authenticated ? (
                <SXUser>
                  <SXUserName>
                    <Text type="p" fontWeight={700}>
                      Hi, {getFirstName(userData?.name)}
                    </Text>
                  </SXUserName>
                  <SXAvatar>
                    <img
                      src={
                        userData?.cover?.url ||
                        "/assets/common/default/avatar.png"
                      }
                      alt=""
                      width={40}
                      height={40}
                    />
                  </SXAvatar>
                </SXUser>
              ) : (
                <Button
                  buttonColor={InspiColors.InspirationBlue500}
                  buttonVariant="outlined"
                  buttonSize="medium"
                  width="auto"
                  onClick={handleClick}
                >
                  Sign In
                </Button>
              )}
            </React.Fragment>
          )}
        </SXInnerContainer>
      </Section>
    </SXHeader>
  );
};

export default Header;
