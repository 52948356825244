import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

// Request
import Request from "../../redux/request";

// Styled
import {
  SXDetailPostPage,
  SXReplyContainer,
  SXSpinner,
} from "./DetailPostPage.styled";

// api
import { getPostById } from "../../utils/apiroutelist";

// Components
import PostContent from "../../components/PostContent/PostContent";
import ReplyBox from "../../components/ReplyBox/ReplyBox";
import ReplyContent from "../../components/ReplyContent/ReplyContent";
import useReplies from "./useReplies";
import EmptyContent from "../../components/EmptyContent/EmptyContent";
import { DetailPostPageLoader } from "../../utils/loader";

const DetailPostPage = () => {
  const { postId } = useParams();

  const [postDetail, setPostDetail] = useState({
    loading: true,
    status: null,
    data: {},
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    getPost(postId);
  }, [postId]);

  const getPost = async (postId) => {
    const request = new Request(null, null, true);
    const response = await request.get(getPostById(postId));
    setPostDetail({
      loading: false,
      status: response?.status,
      data: {
        ...response?.data?.data,
      },
    });
  };

  const {
    replies,
    reply,
    handleChangeInput,
    handleSubmitReply,
    errorAddReply,
  } = useReplies(postId);

  const replyContent = (replies) => {
    if (replies.status !== 200 || replies.data.length == 0) {
      return <EmptyContent text={"Belum ada komentar untuk topik ini"} />;
    }

    const content = replies.data.map((reply, i) => {
      return <ReplyContent key={i} reply={reply} />;
    });

    return content;
  };

  return (
    <SXDetailPostPage>
      {postDetail.loading ? (
        <DetailPostPageLoader height={170} width={"100%"} />
      ) : (
        <PostContent
          post={postDetail.data}
          isList={false}
          isDetailPage={true}
        />
      )}
      <ReplyBox
        show={true}
        handleChangeInput={handleChangeInput}
        handleSubmitReply={handleSubmitReply}
        reply={reply}
        errorAddReply={errorAddReply}
      />
      <SXReplyContainer>
        {replies.loading ? (
          <SXSpinner>
            <MoonLoader size={40} color={InspiColors.JetBlack400} />
          </SXSpinner>
        ) : (
          replyContent(replies)
        )}
      </SXReplyContainer>
    </SXDetailPostPage>
  );
};

export default DetailPostPage;
