// Libraries
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

export const SXDetailForumContainer = styled.div``;

export const SXHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  gap: 20px;

  @media (max-width: ${breakpoints.mobileM}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const SXLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  img {
    width: 56px;
    height: 56px;
  }

  @media (max-width: ${breakpoints.mobileM}) {
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const SXDotButton = styled.div``;

export const SXHeaderInformation = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #eaeae9;
  padding-right: 2rem;

  @media (max-width: ${breakpoints.tabletM}) {
    border-right: none;
  }

  @media (max-width: ${breakpoints.mobileL}) {
    display: none;
  }
`;

export const SXHeaderResponsive = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobileL}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SXRuleContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: #e6eef8;
  justify-content: space-between;
  border-radius: 20px;
  margin-top: 10px;
`;

export const SXAssetRuleContainer = styled.div`
  display: flex;
  gap: 20px;
  height: 40px;
`;

export const SXRuleImage = styled.div`
  max-width: 100px;
  height: auto;

  img {
    width: 100%;
    height: -webkit-fill-available;
  }
`;

export const SXHeaderAction = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  button {
    width: 160px;
  }

  @media (max-width: ${breakpoints.tabletM}) {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    padding: 0 2rem;
    background-color: white;
    border-top: 1px solid #eaeae9;
    box-shadow: 0px -2px 10px rgba(41, 38, 35, 0.1);

    button {
      width: 100%;
    }
  }
`;

export const SXDivider = styled.div`
  margin-top: 20px;
  border-bottom: 2px solid rgb(234, 234, 233);
`;

export const SXList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 20px;
`;

export const SXSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15rem;
`;
