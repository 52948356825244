// Libraries
import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Contexts
import NotificationContext from "../../contexts/NotificationContext";

// Components
import { Text } from "@inspigoid/inspids-react";

// Styled
import { Wrapper } from "./Notification.styled";

const notificationMotions = {
  initial: {
    x: "calc(50vw - 50%)",
    y: "-5rem",
    opacity: 0,
  },
  animate: {
    x: "calc(50vw - 50%)",
    y: "2rem",
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const Notification = () => {
  const notification = useContext(NotificationContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <AnimatePresence>
      {notification.show && (
        <Wrapper
          as={motion.div}
          type={notification.type}
          variants={notificationMotions}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Text type="p" fontWeight={600} color="white">
            {notification.message}
          </Text>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
