import Request from "../../request";
import { forumType } from "../../types/forumType";
import { content } from "../../../utils/apiroutelist";
import { apiStart, apiEnd, apiError } from "../account/_login";

export const getForum = (response) => {
  return {
    type: forumType.GET_FORUM,
    payload: {
      loading: false,
      status: response.status,
      data: response.data.data,
    },
  };
};

export const getForumPath = (response) => {
  return {
    type: forumType.GET_FORUM_PATH,
    payload: {
      loading: false,
      status: response.status,
      data: response.data.data,
    },
  };
};

export const fetchForum = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(apiStart());

    try {
      const request = new Request(null, null, true);
      const response = await request.get(content.forum());
      dispatch(getForum(response));
      dispatch(apiEnd());
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchForumPath = (path) => async (dispatch) => {
  dispatch(apiStart({ message: "Mendapatkan Forum By Path" }));

  try {
    const request = new Request(null, null, true);
    const response = await request.get(content.forumByPath(path));
    dispatch(getForumPath(response));
    dispatch(apiEnd({ message: "Berhasil mendapatkan Forum By Path" }));
  } catch (error) {
    dispatch(apiError({ message: "Gagal mendapatkan Forum By Path" }));
    throw error.response || error;
  }
};
