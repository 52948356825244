// Libraries
import styled from "styled-components";
import { variant } from "styled-system";
import { compose } from "redux";

// breakpoins
import { breakpoints } from "../../utils/breakpoints";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

// Type
import { NotificationType } from "../../contexts/NotificationContext";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(157, 157, 157, 0.25);
  color: white;
  z-index: 9999999;

  ${compose(
    variant({
      prop: "type",
      variants: {
        [NotificationType.Success]: {
          backgroundColor: InspiColors.CarribeanGreen600,
        },
        [NotificationType.Warning]: {
          backgroundColor: InspiColors.CrayolaYellow600,
        },
        [NotificationType.Error]: {
          backgroundColor: InspiColors.SizzlingRed600,
        },
      },
    })
  )}

  @media (max-width: ${breakpoints.mobileL}) {
    top: -2rem;
    width: 100%;
    padding: 3.5rem;
  }
`;
